<template>
  <div>
    <div v-if="!dataFetched">
      <detailPageLoader></detailPageLoader>
    </div>
    <div class="container" v-else>
      <div class="section-1">
        <video v-if="contentDetails.trailerUrl && !isMobile" class="video" autoplay :src="contentDetails.trailerUrl"
          muted="muted" id="vid" :poster="getPoster()" loop>
        </video>
        <div class="image-detail-ratio">
          <img :src="getPoster() ? getPoster() : placeholder_lands" class="carousel-poster-img"
            :alt="contentDetails.title" />
        </div>
        <div class="btn pointer mute" @click="ChangeMute" v-if="contentDetails.trailerUrl">
          <VolumeIcon :status="ismuted ? 'mute' : 'unmute'" />
        </div>
        <div class="image-wrapper">
          <div class="info">
            <div>
              <div class="title">{{ $t(`${contentDetails.title}`) }}</div>
            </div>
            <div class="mob-abjust">
              <div class="mob-adjust-2">
                <div class="general-info">
                  <div class="channel-no" v-if="contentDetails.channelno">CH NO : {{ contentDetails.channelno }}</div>
                  <div class="catchup-period" v-if="contentDetails.catchupperiod">{{ catchUPText }}</div>
                  <div class="pg-rating" v-if="contentDetails.pgrating">{{ contentDetails.pgrating }}</div>
                  <div class="year" v-if="contentDetails.productionyear">{{ contentDetails.productionyear }}</div>
                  <div class="duration"
                    v-if="this.calcTimeFormat(contentDetails.duration) && contentDetails.objecttype !== 'SERIES'">
                    {{ this.calcTimeFormat(contentDetails.duration) }}</div>
                </div>
                <div class="tags">
                  <div>{{ $t(contentDetails.category) }}</div>
                  <div>{{ contentDetails.genre }}</div>
                  <div v-if="contentDetails && contentDetails.contentlanguage && contentDetails.contentlanguage[0]">
                    {{ $t(contentDetails.contentlanguage[0]) }}</div>
                </div>
                <div class="description">
                  <Description :toggle="false" :descStyle="descStyle" :whereIAmFrom="'detailsPage'"
                    :content="contentDetails" />
                </div>
                <div class="renal-validity" v-if="expireTxt"> {{ $t('rental-validity') }} <span>{{ expireTxt }}</span>
                </div>
              </div>
              <div class="buttons">
                <div v-if="contentDetails.objectstatus == 'PRELAUNCH'">
                  <button style="display: flex; justify-content: center;" class="button-primary" disabled
                    @click="watchTrailer()">
                    {{ $t('COMING SOON') }}
                  </button>
                </div>
                <div v-else>
                  <div v-if="contentDetails.objecttype == 'SERIES' && !noEpisodeData && buttonStatus != null">
                    <button style="display: flex; justify-content: center;" class="button-primary" disabled
                      v-show="!buttonStatus">
                      <DotFlashing />
                    </button>
                    <button class="button-primary  button-status one" v-if="!currentEpisode.watchedduration"
                      @click="handleTVSeries('play')" v-show="buttonStatus">
                      {{ $t(buttonStatus == 'PLAY' ? 'WATCH NOW' : buttonStatus) }}
                    </button>
                    <button style="display: flex; justify-content: center;" class="button-primary"
                      @click="handleTVSeries('resume')"
                      v-else-if="currentEpisode.watchedduration && !(contentDetails.tags && contentDetails.tags.toString().toLowerCase().includes('ct-music'))"
                      v-show="buttonStatus">
                      {{ watchedEpisodeButton }} <p style="margin-left: 3px;">({{ Math.floor((currentEpisode.duration -
                        currentEpisode.watchedduration) / 60) }}min left)</p>
                    </button>
                    <button style="display: flex; justify-content: center;" class="button-primary"
                      @click="handleTVSeries('resume')" v-else>
                      <p style="margin-left: 3px;">{{ $t("RESUME") }} {{ $t("Track") }}
                        ({{ Math.floor((currentEpisode.duration - currentEpisode.watchedduration) / 60) }}min left)</p>
                    </button>
                  </div>
                  <div v-else-if="buttonStatus != null">
                    <button style="display: flex; justify-content: center;" class="button-primary" disabled
                      v-if="!buttonStatus">
                      <DotFlashing />
                    </button>
                    <button style="display: flex; justify-content: center;" class="button-primary"
                      @click="resumeHandle()" v-else-if="buttonStatus && contentDetails.watchedTime">
                      {{ $t("RESUME") }}
                      <p style="margin-left: 3px;">
                        ({{ playerPositionPerecentage }} {{ timeleft }})
                      </p>
                    </button>
                    <button id="buttonStatus" class="button-primary btn-size-fix buttonStatus" v-else
                      @click="buttonHandler">
                      {{ $t(buttonStatus == "PLAY" ? "WATCH NOW" : buttonStatus) }}
                    </button>
                  </div>
                </div>
                <div class="secondary-btns">
                  <div class="btn pointer">
                    <CspaceLikeBtn :content="contentDetails" />
                  </div>
                  <div class="btn pointer">
                    <CspaceDetailShare :content="contentDetails" />
                  </div>
                  <div class="btn pointer">
                    <CspaceWatchlistBtn :content="contentDetails" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-2">
        <Tab :tabs="tabs" v-if="tabs.length > 0">
          <template v-slot="{ tab }" >
            <div v-if="tab.name === 'Episodes'">
              <episodeCard :contentDetails="contentDetails" :playerInstance="playerInstance"
                @totalEpisodeListCB="totalEpisodeListCB" @setCurrentEpisodeInformation="setCurrentEpisodeInformation">
              </episodeCard>
            </div>
            <div v-if="tab.name === 'Related'">
              <div class="tab-container">
                <RelatedSection></RelatedSection>
              </div>
            </div>
            <div v-if="tab.name === 'Details'">
              <div class="cast-crew" v-if="contentDetails.castncrew">
                <div class="cast">
                  <RoundSlider :title="'Cast'" :id="'first'" :castncrew="contentDetails.castncrew.cast"></RoundSlider>
                </div>
                <div class="crew">
                  <RoundSlider :title="'Crew'" :id="'two'" :castncrew="contentDetails.castncrew.crew"></RoundSlider>
                </div>
              </div>
              <div v-else class="no-found">
                <div class="EmptyContainer">
                  <EptyBin></EptyBin>
                  <p> {{ $t("No Details found") }} </p>
                </div>
              </div>
            </div>
            <div v-if="tab.name === 'Trailer'">
              <TrailerCom :contentDetails="contentDetails" :playerInstance="playerInstance"></TrailerCom>
            </div>
            <div v-if="tab.name === 'Program Guide'" class="program-guide">
              <EpgContainer :headerContainer="false" :channelCard="false" :channelno="contentDetails.channelno"></EpgContainer>
            </div>
          </template>
        </Tab>
        <TabsPlaceHolder v-else />
      </div>
    </div>
    <PinModal id="pc-pin-detail-modal-player-config" :open="showPinModal" @onClose="closePinModal" :mode="pinMode"
      @onActionComplete="onPinModalActionComplete" :pinError="pinModalError"
      :callbackParentalLockFlag="callbackParentalLockFlag" :loading="pinModalLoading"
      @onForgotPin="openForgotPinModal" />
    <ForgotPinModal id="pc-forgot-pin-modal" :open="showForgotPinModal" @onClose="closeForgotPinModal"
      @onActionComplete="onForgotPinModalActionComplete" :pinError="forgotPinModalError" @onResend="resendOtp"
      :loading="loading" />
    <Popup v-if="isLiveContentAccessError" islogoNotRequired="false" width="max-content" margin="15% auto"
      radiusType="full" :closePopupEvent="() => { closePopup() }">
      <div class="confirmation-box" slot="component">
        <div class="confirmation-body">
          <h5>{{ $t("liveContentAccessError")}}</h5>
          <div class="login-button">
            <button class="button-primary login" type="Button" @click.stop="closePopup()">
              {{ $t("OK") }}
            </button>
          </div>
        </div>
      </div>
    </Popup>
  </div>
</template>

<script>
import { vLivePlaylistItem } from "/src/vue-player/vLiveWebPlayer.js";
import Utility from "@/mixins/Utility.js";
import Redirection from "@/mixins/redirection.js";
import { eventBus } from "@/eventBus";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { store } from "@/store/store";
import { showSnackBar } from "@/utilities";
import firebase from "firebase/app";
import "firebase/database";
import Tab from '../tab/Tab.vue';

export default {
  props: {
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      tabs: [],
      ismuted: true,
      Mute: require('@/assets/icons/mute.png'),
      Unmute: require('@/assets/icons/unmute.png'),
      GetKidProfile: "",
      dataFetched: false,
      localDisplayLang: null,
      contentDetails: {},
      contentId: null,
      readMore: false,
      tabList: [],
      selectedSection: null,
      activeTab: "active-tab",
      activeComponent: "",
      showErrorMessage: false,
      seasonNum: 1,
      seasonCount: null,
      seasonCountArray: [],
      subscriptions: [],
      availabilities: [],
      purchases: [],
      filteredAvailabilities: [],
      pricemodel: [],
      buttonStatus: "",
      fetchStreamUrlTask: null,
      playbackInitiated: false,
      isMpegRequired: false,
      isDrmFree: true,
      progress: null,
      watchedTime: null,
      playerPositionPerecentage: null,
      currentEpisode: "",
      watchedEpisodeButton: "",
      showPinModal: false,
      pinModalError: "error message",
      pinMode: "restrict",
      testLangArr: ["Spanish", "English", "Hindi"],
      callbackParentalLockFlag: "",
      confirmDetailParentPin: null,
      isLoaderEnabled: false,
      shareLink: null,
      pinModalLoading: false,
      parentalControlsEnabled: false,
      disableLanguage: false,
      configCTTag: false,
      showForgotPinModal: false,
      forgotPinModalError: "",
      forgetpinloader: false,
      loading: false,
      rentNowPriceModel: {},
      isPopupClose: false,
      availPlanListPopup: {},
      rentPurchasedActive: false,
      expireTxt: '',
      userInfoDetails: [],
      timeleft: "",
      isARentalContent: false,
      isMobile: false,
      chanelBtnStatus: "",
      seriesBtnStatus: "",
      descStyle: {
        font: 1,
        width: 100
      },
      placeholder_lands: require('@/assets/placeholder-lands.svg'),
      noEpisodeData: false,
      episodesList: null,
      isLiveContentAccessError: false,
    };
  },
  watch: {
    $route(to, from) {
      this.scrollTop();
      this.dataFetched = false;
      this.selectedSection = null;
      this.tabs = [];
      this.contentDetails = null;
      this.contentId = to.params.contentId;
      this.getContentDetail(this.contentId);
    },
    availabilityList(val) {
      if (Array.isArray(val) && val.length > 0) {
        this.availabilities = val;
      }
    },
    subscriptionList(val) {
      if (Array.isArray(val) && val.length > 0) {
        this.subscriptions = val;
      }
    },
    purchaseList(val) {
      this.purchaseListData = val;
      this.getPurchaseListCode();
    },
    getContentData(val) {
      this.contentDetails = val;
    }
  },
  created() {
    // this.isMpegRequired = this.checkMpegRequired();
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.localDisplayLang = this.localDisplayLang == 'ml' ? 'mal' : this.localDisplayLang;
    localStorage.setItem("paymentGetwaySaveUrl", location.href)

    try {
      this.userInfoDetails = localStorage.getItem("subscriberDetails") ? JSON.parse(localStorage.getItem("subscriberDetails")).data : [];
    } catch (err) {
      // console.log(err)
    }


    let subscriberProfileDetails = localStorage.getItem(
      "subscriberProfileDetails"
    );
    let isenabled = localStorage.getItem("parentalControlEnabled") == 'true' ? true : false;
    this.parentalControlsEnabled = isenabled;
    if (subscriberProfileDetails) {
      subscriberProfileDetails = JSON.parse(subscriberProfileDetails);
    }

    this.contentId = this.$route.params.contentId;
    eventBus.$on(`contentDetail-response-${this.contentId}`, (response) => { });

    //assigning subscription list response.
    if (this.subscriptionList !== undefined) {
      this.subscriptions = this.subscriptionList;
    }
    if (this.purchaseList !== undefined) {
      this.purchaseListData = this.purchaseList;
      this.getPurchaseListCode()
    }

    //To check for newly added firebase data.
    eventBus.$on("check-new-fire-data", async () => {
      // this.checkEpisodeContinueWatch();
      if (this.subscriberid) {
        await this.getContentProgress(this.contentDetails);
      }
    });
    this.fetchAvailability();
    eventBus.$on("details-login-success", async () => {
      this.buttonStatus = "";
      await this.getPlayBackAvailabilityStatus(this.contentDetails);
      await this.getContentProgress(this.contentDetails);
    });
  },

  computed: {
    ...mapGetters([
      "appConfig",
      "subscriberid",
      "availabilityList",
      "subscriptionList",
      "purchaseList",
      "getAudioAndSubtitleLangState",
      "getAllLanguageFullForm",
      "profileid",
      "getSubscriptionInformation",
      "getContentData"
    ]),

    checkScreenWidth: function () {
      if (screen.width > 900) {
        return true;
      } else {
        return false;
      }
    },

    contentTags() {
      return this.contentDetails.tags[0].toLowerCase();
    },

    seasons: function () {
      if (
        this.contentDetails &&
        this.contentDetails.seasoncount &&
        this.contentDetails.seasoncount > 1
      ) {
        return this.contentDetails.seasoncount + " " + "Seasons";
      } else if (
        this.contentDetails &&
        this.contentDetails.seasoncount &&
        this.contentDetails.seasoncount <= 1
      ) {
        return this.contentDetails.seasoncount + " " + "Season";
      } else {
        return "";
      }
    },
    actGetEpisodeList: function (data) {
      // console.log(data)
    },
    checkDescription: function () {
      if (
        (this.contentDetails && this.contentDetails.longdescription) ||
        (this.contentDetails && this.contentDetails.shortdescription)
      ) {
        return true;
      } else {
        return false;
      }
    },

    checkDescriptionLength: function () {
      if (
        (this.contentDetails &&
          this.contentDetails.longdescription &&
          this.contentDetails.longdescription.length > 250) ||
        (this.contentDetails &&
          this.contentDetails.shortdescription &&
          this.contentDetails.shortdescription.length > 250)
      ) {
        return true;
      } else {
        return false;
      }
    },

    checkMovie: function () {
      if (this.contentDetails.objecttype == 'SERIES') {
        return true;
      } else {
        return true;
      }
    },

    catchUPText() {
      const displayText = this.appConfig?.displayText;
      return (
        displayText?.[this.localDisplayLang]?.CatchUPText ||
        displayText?.default?.CatchUPText || 'CU TV'
      );
    },
  },
  mounted() {
    this.scrollTop();
    this.contentId = this.$route.params.contentId;
    this.getContentDetail(this.contentId);
    this.createLink("sharing");
    this.timeleft = this.$t("minutesLeft")
    document.getElementById('vid') && document.getElementById('vid').play();

    this.isMobile = window.innerWidth <= 600 ? true : false,


      this.GetKidProfile = localStorage.getItem("isKidsMode");
    this.getEpisodeContentProgress();
    this.setAudioAndSubtitleLangState(null);

    eventBus.$on("changeLanguageView", () => {
      location.reload();
    });
    this.disableLanguage = window.PROVIDER_SETUP_CONFIG.isActiveDetailLanguage
    setTimeout(() => {
      if (
        this.$route.fullPath.includes("play") &&
        this.$route.fullPath.includes("trailer")
      ) {
        this.watchTrailer();
      } else if (this.$route.fullPath.includes("play")) {
        if (this.subscriberid) {
          this.buttonHandler("movie");
        } else {
          let payload = {
            state: true,
            formType: "lookup",
          };
          eventBus.$emit("authPopup", payload);
        }
      }
    }, 2000);



    eventBus.$on("player-visibility", (isVisible) => {
      if (isVisible == false && this.playbackInitiated == true) {
        this.playbackInitiated = false;
        this.playerInstance.deregisterEvent("currentcontentended");
      }
    });

  },
  components: {
    Tab,
    TrailerCom: () => import('@/components/DetailPage/trailerCard.vue'),
    Slider: () => import('@/themeearth/components/dashboard/sliderCarousel/Slider.vue'),
    RoundSlider: () => import("@/themeearth/components/dashboard/sliderCarousel/RoundSlider.vue"),
    RelatedSection: () => import("@/components/DetailPage/relatedCard.vue"),
    detailPageLoader: () => import(/* webpackChunkName: "detailPageLoader" */ "@/components/DetailPage/detailPageLoader.vue"),
    CspaceLikeBtn: () => import(/* webpackChunkName: "likebutton" */ "../../../components/SvgImages/CspaceLikeBtn.vue"),
    CspaceDetailShare: () => import(/* webpackChunkName: "Detailbutton" */ "../../../components/SvgImages/CspaceShareBtn.vue"),
    CspaceWatchlistBtn: () => import(/* webpackChunkName: "watchbutton" */ "../../../components/SvgImages/CspaceWatchlistBtn.vue"),
    VolumeIcon: () => import(/* webpackChunkName: "volumeIcon" */ "@/components/SvgImages/Volume.vue"),
    Description: () => import(/* webpackChunkName: "description" */ "@/components/Text/Description.vue"),
    episodeCard: () => import(/* webpackChunkName: "description" */ "@/components/DetailPage/episodeCard.vue"),
    DotFlashing: () => import(/* webpackChunkName: "dotFlashing" */ "@/components/Loaders/DotFlashing.vue"),
    EptyBin: () => import(/* webpackChunkName: "eptyBin" */ "@/components/SvgImages/Empty.vue"),
    ForgotPinModal: () => import("@/components/utils/ForgotPinModal.vue"),
    PinModal: () => import(/* webpackChunkName: "PinModal" */  "@/components/utils/PinModal.vue"),
    TabsPlaceHolder: () => import(/* webpackChunkName: "tabsPlaceHolder" */ "@/components/placeholders/TabsPlaceHolder.vue"),
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
    EpgContainer: () => import(/* webpackChunkName: "epgContainer" */ "@/components/Epg/EpgContainer.vue")
  },
  methods: {
    ...mapMutations(["setRegActiveDeeplink", "setAudioAndSubtitleLangState"]),
    ...mapActions([
      "getLanguagesByCodes",
      "parentalControlVerifyOldPin",
      "authorizeCurrentStreamApi",
      "setCurrentStreamApi",
      "parentalControlPinSet",
      "parentalControlResetPin",
      "availabilityDetail",
      "subscriberPlaybackExpiry"
    ]),

    videoPlayListPlayerUnloaded() {
      // alert("Unloaded video");
    },

    ChangeMute() {
      this.ismuted = !this.ismuted;
      const video = document.getElementById('vid');
      video.muted = !video.muted;
    },

    fetchAvailability() {
      let page = 1;
      let pageSize = 15;
      let payload = {
        page: page,
        pagesize: pageSize,
      };
      store
        .dispatch("availabilityList", payload)
        .then((response) => {
          this.availabilities = response.data.data;
        })
    },

    getFormatedDate(list) {
      let d = "";
      d = list.expiry
      if (list.expiryafterfirstplay) {
        d = list.expiryafterfirstplay
      }
      function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
      }

      try {
        let newDate = new Date(d);

        newDate.setHours(newDate.getHours() + 5);
        newDate.setMinutes(newDate.getMinutes() + 30);

        return newDate.getDate() + " " + newDate.toLocaleString('default', {
          month: 'short'
        }) + " " + newDate.getFullYear() + ", " + formatAMPM(newDate);
      } catch (e) {
        return d;
      }

    },

    getPurchaseListCode() {
      for (let list of this.purchaseListData) {

        if ((list.objectid).toLowerCase() == (this.contentId || this.$route.params.contentId).toLowerCase()) {
          this.rentPurchasedActive = true;
          this.expireTxt = this.getFormatedDate(list);

        }
      }
    },

    calcTimeFormat(val) {
      if (!val) return;
      let totalTime = val;
      let hours = Math.floor(totalTime / 3600);
      let minutes = Math.floor((totalTime - hours * 3600) / 60);

      let showTime =
        (hours ? hours.toString().padStart(2, "0") + this.$t("hr") : "") +
        " " +
        (minutes ? minutes.toString().padStart(2, "0") + (minutes === 1 ? this.$t("min") : this.$t("mins")) : "");

      if (showTime == " ") {
        return val + this.$t("secs");
      } else {
        return showTime;
      }
    },

    resendOtp() {
      //do something here. (Make call to the API)
      this.loading = true;
      let payload = {
        otp_type: "parentalControlPinUpdate",
      };
      //do something here. (Make call to the API)
      //received otp to email
      this.parentalControlPinSet(payload)
        .then((data) => {
          this.loading = false;
          if (data.data.reason) {
            this.pinModalError = this.$t(data.data.reason);
            return;
          }
        })
        .catch((error) => {
          // console.log("parentalControlPinSet error -> ", error);
        });
    },

    closeForgotPinModal() {
      this.showForgotPinModal = false;
    },

    onForgotPinModalActionComplete(data) {
      // forget pin reset api call
      this.loading = true;
      let forgetPinPayload = {
        pin: data.pin,
        OTP: data.otp,
      };
      this.parentalControlResetPin(forgetPinPayload)
        .then((data) => {
          this.loading = false;
          if (data.data.reason) {
            this.forgotPinModalError = this.$t(data.data.reason);
            return;
          } else if (data.data.success) {
            this.showForgotPinModal = false;
          }
        })
        .catch((error) => {
          // console.log("parentalControlResetPin error -> ", error);
        });
    },

    openForgotPinModal() {
      const isKidsMode = localStorage.getItem("isKidsMode") === "true";
      if (isKidsMode) {
        this.pinModalError = this.$t("Unauthorized access");
        // return;
      }
      this.showForgotPinModal = true;
      this.loading = true;


      let payload = {
        otp_type: "parentalControlPinUpdate",
      };

      this.parentalControlPinSet(payload)
        .then((data) => {
          this.loading = false;
          if (data.data.reason) {
            this.pinModalError = this.$t(data.data.reason);
          }
        })
        .catch((error) => {
          // console.log("parentalControlPinSet error -> ", error);
        });
    },

    actRedirectionMetaTag(type, tag) {
      let section = (tag.toString()).toLowerCase()
      let currentLanguage = this.getCurrentLanguageUrlBase();
      return ({ name: "screen-meta-redirection", params: { objecttype: (this.contentDetails.objecttype).toLowerCase(), value: section, type: type, lang: currentLanguage } });
    },

    onRouteChange(clickedObj) {
      let currentLanguage = this.getCurrentLanguageUrlBase();

      if (clickedObj.title === "Home") {
        this.$router.push({ name: clickedObj.title, params: { lang: currentLanguage } });
        return;
      }
      if (clickedObj.reference === "CONTENT") {
        this.$router.push({
          name: "section-screen",
          params: { section: "movies", lang: currentLanguage },
        });
      }
      else if (clickedObj.reference === "SERIES" || clickedObj.reference.toUpperCase() === "NATAK") {
        this.$router.push({
          name: "section-screen",
          params: { section: "shows", lang: currentLanguage },
        });
      } else if (clickedObj.reference.toUpperCase() === "MUSIC") {
        this.$router.push({
          name: "section-screen-video",
          params: { section: 'music', lang: currentLanguage },
        });
      } else {
        this.$router.push({ name: "Home", params: { lang: currentLanguage } });
      }
    },

    openPinModal() {
      this.showPinModal = true;
    },

    totalEpisodeListCB(val) {
      if (!val) {
        this.noEpisodeData = true;
      }
      if (this.availabilities.length == 0) {
        this.availabilities = this.availabilityList;
      };
      this.episodesList = val;
    },

    async contentRenderSetup(content) {
      this.dataFetched = true;
      this.contentDetails = content;
      setTimeout(() => this.fetchTabList(), 500);
      this.contentDetails.trailerUrl = this.contentDetails.trailer && this.contentDetails.trailer[0] && this.contentDetails.trailer[0].filelist[0].filename;
      if (this.subscriberid) {
        await this.getContentProgress(content);
      }
      // this.getUpdatedTag();
      let subscriberProfileDetails = localStorage.getItem(
        "subscriberProfileDetails"
      );
      if (subscriberProfileDetails) {
        subscriberProfileDetails = JSON.parse(subscriberProfileDetails);
      }

      if (this.availabilities.length == 0) {
        this.availabilities = this.availabilityList;
      }
      if (!(this.contentDetails.objecttype == 'SERIES' && this.contentDetails.objecttype == 'SHOWS')) {
        if (this.subscriberid) {
          this.getPlayBackAvailabilityStatus(this.contentDetails);
        } else {
          this.buttonStatus = "LOGIN & WATCH";
        }
      }
      this.playerPositionPerecentage = this.contentDetails
        ? this.contentDetails.duration - this.contentDetails.watchedTime
        : this.contentDetails.duration - this.contentDetails.watchedTime;
      this.playerPositionPerecentage = Math.floor(
        this.playerPositionPerecentage / 60
      );
      window.document.title = 'Watch' + ' ' + (this.contentDetails.defaulttitle || this.contentDetails.title) + ' on KEE';
      this.seasonNum = this.contentDetails.seasoncount;
      if (
        subscriberProfileDetails &&
        subscriberProfileDetails.kidsmode === "YES" &&
        this.contentDetails.defaultgenre !== "KIDS"
      ) {
        showSnackBar(this.$t("Content is not accessible"));
        this.$router.replace("/");
      }
    },

    closePinModal() {
      this.showPinModal = false;
      this.callbackParentalLockFlag = "";
    },

    onPinModalActionComplete(enteredPin) {
      this.isLoaderEnabled = true;
      this.pinModalLoading = true;

      this.confirmDetailParentPin = enteredPin;
      let payload = {
        pin: this.confirmDetailParentPin,
      };
      this.parentalControlVerifyOldPin(payload)
        .then((data) => {
          this.pinModalLoading = false;
          if (data.data.reason) {
            this.pinModalError = this.$t(data.data.reason);
            return;
          } else if (data.data.success) {
            //this.showPinModal = false;
            this.isLoaderEnabled = false;

            if (this.callbackParentalLockFlag == "MOVIE-RESUME") {
              this.resumeHandle(true);
            } else if (this.callbackParentalLockFlag == "MOVIE-PLAY") {
              this.buttonHandler(false, true);
            } else if (this.callbackParentalLockFlag == "SERIES-RESUME") {
              this.handleTVSeries(true, true);
            } else if (this.callbackParentalLockFlag == "SERIES-PLAY") {
              this.handleTVSeries(false, true);
            }

            this.closePinModal();
          }
        })
        .catch((error) => {
          // console.log("parentalControlVerifyOldPin error -> ", error);
        });
    },

    async setCurrentEpisodeInformation(val) {
      // console.log("setCurrentEpisodeInformation", val.watchedduration)
      this.currentEpisode = val;
      if (val.watchedduration) {
        this.watchedEpisodeButton =
          this.$t("RESUME") +
          " " +
          "S" +
          this.currentEpisode.seasonnum +
          ":E" +
          this.currentEpisode.episodenum;
      }
      if (this.availabilities.length == 0) {
        this.availabilities = this.availabilityList;
      }

      // this.contentDetails.watchedTime = val.watchedduration

      await this.getPlayBackAvailabilityStatus(this.currentEpisode);
    },

    handleTVSeries(e, status = false) {
      let activeRent = false;
      for (let list of this.purchaseListData) {
        if ((list.objectid).toLowerCase() == (this.currentEpisode.objectid).toLowerCase()) {
          activeRent = true;
        }
      }
      if (!activeRent && this.subscriberid && (this.buttonStatus == "RENT NOW" || this.buttonStatus == "PURCHASE NOW")) {
        if (this.subscriberid !== this.profileid) {
          eventBus.$emit("showAuthorizeErrorMessage", {
            state: true,
            messageData: {
              title: this.$t("Error"),
              message: this.$t("Sorry! Admin only allowed to the payment. Please contact your Admin")
            },
          });
          return;
        }
        eventBus.$emit("open-home-rzp-modal", this.rentNowPriceModel);
        return;
      } else if (!this.subscriberid && (this.buttonStatus === "PLAY" || this.buttonStatus === "LOGIN & WATCH")) {
        //open lookup popup.
        let payload = {
          state: true,
          formType: "lookup",
        };
        eventBus.$emit("authPopup", payload);
        return;
      } else if (this.subscriberid && this.buttonStatus === "SUBSCRIBE & WATCH" && this.subscriberid !== this.profileid) {
        eventBus.$emit("showAuthorizeErrorMessage", {
          state: true,
          messageData: {
            title: "Error",
            message: this.$t(
              "Sorry! Admin only allowed to the payment. Please contact your Admin"
            ),
          },
        });
        return;
      } else if (this.subscriberid && this.buttonStatus === "SUBSCRIBE & WATCH") {
        // For master Profile

        this.setRegActiveDeeplink(true);
        //show subscribe popup.
        // let payload = { listType: "onlySubscribe" };
        // eventBus.$emit("subscribePopup", payload);
        localStorage.setItem("paymentGetwaySaveUrl", location.href)

        let currentLanguage = this.getCurrentLanguageUrlBase();
        this.$router.push({ name: 'seeAllPlans', params: { lang: currentLanguage } });
        return;
      }

      if (e == "resume") {
        this.showPinModal = false;
        if (!this.isContentPlayableParentalLock(this.getCurrentProfile(), this.contentDetails.pgrating) && status) {
          if (this.parentalControlsEnabled) {
            this.callbackParentalLockFlag = "SERIES-RESUME";
            this.showPinModal = true;
            return;
          }
        }
        eventBus.$emit("cbPlayerCallCounter", "resume");
      } else {
        if(this.contentDetails.playbacktype == 'LOCATORBASEDPLAYBACK') {
          this.redirectToLocator(this.currentEpisode);
          return;
        }
        if (
          !this.isContentPlayableParentalLock(
            this.getCurrentProfile(),
            this.contentDetails.pgrating
          ) &&
          !status
        ) {

          if (this.parentalControlsEnabled) {
            this.callbackParentalLockFlag = "SERIES-PLAY";
            this.showPinModal = true;
            return;
          }

        }
        eventBus.$emit("cbPlayerCallCounter", "play");
      }
    },

    async getContentProgress(content) {
      const path = `/subscriber/${this.subscriberid}/${this.profileid}/content/${content.objectid}`;

      try {
        const snapshot = await new Promise((resolve, reject) => {
          firebase
            .database()
            .ref(path)
            .on("value", (snapshot) => {
              resolve(snapshot);
            }, (error) => {
              reject(error);
            });
        });

        const response = snapshot.val();
        if (content && response) {
          if (response.status === "INPROGRESS") {
            if (response.objecttype === "CONTENT") {
              const duration = response.duration;
              const watched = response.watchedduration;
              this.watchedTime = response.watchedduration;

              // Update contentDetails reactively
              this.$set(this.contentDetails, 'watchedTime', watched);


              // Calculate player position percentage
              this.playerPositionPerecentage = Math.floor((duration - watched) / 60);

              const progress = (watched / duration) * 100;
              if (progress > 0 && progress < 100) {
                this.progress = progress;
              }
            }
          }
        }
      } catch (error) {
        console.error("Error fetching content progress:", error);
      }
    },

    getEpisodeContentProgress() {
      let content = this.contentDetails;
      if (content.status === "INPROGRESS") {
        let duration = content.duration;
        let watched = content.watchedduration;
        this.watchedTime = content.watchedduration;
        let progress = (watched / duration) * 100;
        if (progress > 0 && progress < 100) {
          this.progress = progress;
        }
      }
    },

    getLanguageFullForm(lang) {
      let fullForm = "";
      if (lang) {
        this.getAllLanguageFullForm.forEach((langObj) => {
          if (langObj["alpha3-b"] === lang) {
            fullForm = langObj.English;
            return;
          }
        });
      }

      return fullForm;
    },

    pickTagForContent(content) {
      if (content.hasOwnProperty("objecttag")) {
        let tag = content.objecttag[0];
        let contenttags = this.appConfig.contentTags;
        if (this.localDisplayLang === "ara") {
          return contenttags.ar[`${tag}`];
        } else {
          return contenttags.en[`${tag}`];
        }
      }
    },

    isObjectTag(content) {
      if (content.hasOwnProperty("objecttag")) {
        if (Array.isArray(content.objecttag)) {
          return content.objecttag.length === 0 ? false : true;
        } else {
          return content.objecttag === null ? false : true;
        }
      }
    },

    checkMpegRequired() {
      let isMpegRequired = false;
      let videoTag = document.createElement("video");
      let platformOs = navigator.platform;

      if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
        if (platformOs.startsWith("Mac")) {
          isMpegRequired = true;
        }
      }

      return isMpegRequired;
    },

    buttonHandler(isDeeplink = false, status = false) {
      if (!this.subscriberid && (this.buttonStatus === "PLAY" || this.buttonStatus === "LOGIN & WATCH" || this.buttonStatus === "SUBSCRIBE & WATCH")) {
        //open lookup popup.
        let payload = {
          state: true,
          formType: "lookup",
        };
        eventBus.$emit("authPopup", payload);
      } else if (this.subscriberid && this.buttonStatus === "SUBSCRIBE & WATCH" && this.subscriberid !== this.profileid) {
        eventBus.$emit("showAuthorizeErrorMessage", {
          state: true,
          messageData: {
            title: "Error",
            message: this.$t(
              "Sorry! Admin only allowed to the payment. Please contact your Admin"
            ),
          },
        });
      } else if (this.subscriberid && this.buttonStatus === "SUBSCRIBE & WATCH") {
        if (this.contentDetails.contentprovider == "CATCHUPTV" || this.contentDetails.category == "TVCHANEL" || this.contentDetails.category == "TVCHANNEL") {
          this.isLiveContentAccessError = true;
          return
        }
        this.setRegActiveDeeplink(true);
        //show subscribe popup.
        // let payload = { listType: "onlySubscribe" };
        // eventBus.$emit("subscribePopup", payload);
        localStorage.setItem("paymentGetwaySaveUrl", location.href)

        let currentLanguage = this.getCurrentLanguageUrlBase();
        this.$router.push({ name: 'seeAllPlans', params: { lang: currentLanguage } });

      } else if (this.subscriberid && (this.buttonStatus === "PLAY" || this.buttonStatus === "WATCH NOW")) {
        if (
          !this.isContentPlayableParentalLock(
            this.getCurrentProfile(),
            this.contentDetails.pgrating
          ) &&
          !status
        ) {
          if (this.parentalControlsEnabled) {
            this.callbackParentalLockFlag = "MOVIE-PLAY";
            this.showPinModal = true;
            return;
          }
        }
        if (this.contentDetails.contentprovider == "CATCHUPTV" || this.contentDetails.category == "TVCHANEL" || this.contentDetails.category == "TVCHANNEL") {
          eventBus.$emit("live-player-active", this.contentDetails);
          return
        } else if (this.contentDetails.playbacktype && this.contentDetails.playbacktype == 'LOCATORBASEDPLAYBACK') {
          this.redirectToLocator(this.contentDetails);
        } else if (this.contentDetails.playbacktype && this.contentDetails.playbacktype == "EXTERNALURLBASEDPLAYBACK") {
          this.showPlayer();
          let contentList = [this.contentDetails];
          this.actBuyDRMToPlayer(0, contentList, contentList.length, true);
        } else {
          this.showPlayer();
          console.log('===>contentDetails', this.contentDetails)
          let contentList = [this.contentDetails];
          this.loadChosenIndexContent(0, contentList, contentList.length, true);
        }
        // this.closePopup();
      } else if (this.subscriberid && (this.buttonStatus == "RENT NOW" || this.buttonStatus == "PURCHASE NOW")) {
        if (this.subscriberid !== this.profileid) {
          eventBus.$emit("showAuthorizeErrorMessage", {
            state: true,
            messageData: {
              title: this.$t("Error"),
              message: this.$t("Sorry! Admin only allowed to the payment. Please contact your Admin")
            },
          });
          return;
        } else {
          eventBus.$emit("open-home-rzp-modal", this.rentNowPriceModel);
        }
      } else if ((this.buttonStatus === "SUBSCRIBE OR PURCHASE" || this.buttonStatus === "SUBSCRIBE OR RENT")) {
        if (this.subscriberid !== this.profileid) {
          eventBus.$emit("showAuthorizeErrorMessage", {
            state: true,
            messageData: {
              title: this.$t("Error"),
              message: this.$t("Sorry! Admin only allowed to the payment. Please contact your Admin")
            },
          });
          return;
        }
        this.isPopupClose = true;
      }
    },

    resumeHandle(status = false) {
      this.showPinModal = false;
      if (
        !this.isContentPlayableParentalLock(
          this.getCurrentProfile(),
          this.contentDetails.pgrating
        ) &&
        !status
      ) {
        if (this.parentalControlsEnabled) {
          this.callbackParentalLockFlag = "MOVIE-RESUME";
          this.showPinModal = true;
          return;
        }

      }
      // console.log('Resume_contentDetails', this.contentDetails)
      let contentList = [this.contentDetails];
      if (Array.isArray(contentList) && contentList[0].playbacktype == "EXTERNALURLBASEDPLAYBACK") {
        this.actBuyDRMToPlayer(0, contentList, contentList.length, true);
      } else {
        this.loadChosenIndexContent(0, contentList, contentList.length, true);
      }
      this.showPlayer();
      // this.loadChosenIndexContent(0, contentList, contentList.length, true);
    },

    cardClickHandler(content) {
      if (content.objecttype == "CONTENT" && this.isDirectPlay) {
        if (
          this.$router.currentRoute.params &&
          this.$router.currentRoute.params.section
        ) {
          localStorage.setItem(
            "playback_source",
            this.$router.currentRoute.params.section
          );
        } else {
          localStorage.setItem("playback_source", "HOME");
        }

        this.playContent(content);
      } else {
        let currentLanguage = this.getCurrentLanguageUrlBase();

        if (screen.width > 0) {
          if (content.category == "TVSHOW") {
            let title = this.spacialCharEncoding(content.defaulttitle);
            this.$router.push({
              name: "detailPage",
              params: {
                contentId: content.objectid,
                mediaTitle: title,
                lang: currentLanguage,
              },
            });
          } else {
            let title = this.spacialCharEncoding(content.defaulttitle);
            this.$router.push({
              name: "detailPageMovie",
              params: {
                mediaTitle: title,
                contentId: content.objectid,
                lang: currentLanguage,
              },
            });
          }
        }
      }
    },

    actBuyDRMToPlayer(episodeIndex, episodeList, totalCount, isFirstTime = true) {
      // this.loadContentMetaData(episodeList[episodeIndex], null);

      let playlist = this.buildPlayerPlaylist(episodeList, false, 0);
      this.playerInstance.loadPlaylist(playlist, totalCount);
      this.playerInstance.loadContent(episodeIndex).then(() => {
        this.playerInstance.play().then(() => {
          // console.log("Playback successful");
        });
      }).catch((err) => { alert(err) });
    },

    loadChosenIndexContent(
      episodeIndex,
      episodeList,
      totalCount,
      isFirstTime = true
    ) {
      let self = this;
      this.fetchContentStreamUrl(episodeList[episodeIndex], self.isMpegRequired)
        .then((resp) => {
          let loadPlayer = true;
          if (self.appConfig.featureEnabled.isConcurrencyEnabled) {
            // Call the Stream Authorize API
            self.authorizeCurrentStreamApi().then((authResponse) => {
              if (!authResponse.data.errorcode) {
                if (typeof resp == "object" && !resp.data.errorcode) {
                  self.handleFetchContentStreamUrl(
                    resp,
                    episodeList[episodeIndex]
                  );
                  loadPlayer = true;
                } else if (typeof resp == "boolean" && resp == true) {
                  loadPlayer = true;
                }
                if (loadPlayer == true) {
                  self.loadContentMetaData(episodeList[episodeIndex], null);

                  let playlist = self.buildPlayerPlaylist(
                    episodeList,
                    false,
                    0
                  );
                  self.playerInstance.loadPlaylist(playlist, totalCount);

                  self.playerInstance.loadContent(episodeIndex).then(() => {
                    self.playerInstance.play().then(() => {
                      const payload = {
                        contentId: episodeList[episodeIndex].objectid,
                        params: {
                          devicetype: "PC",
                        },
                      };

                      self.setCurrentStreamApi(payload).then(
                        (setStreamResponse) => {
                          if (setStreamResponse.data.errorcode) {
                            self.hidePlayer();

                            eventBus.$emit("showAuthorizeErrorMessage", {
                              state: true,
                              messageData: {
                                title: self.$t("Error"),
                                message: self.$t("Device Limit Reached"),
                              },
                            });
                          }
                        }
                      );
                    });
                  });
                } else {
                  let playbackError = true;

                  if (typeof resp == "object") {
                    playbackError = resp.data;
                  }

                  self.hidePlayer();

                  eventBus.$emit("showAuthorizeErrorMessage", {
                    state: true,
                    messageData: {
                      title: self.$t("Error"),
                      message: self.$t("Device Limit Reached"),
                    },
                  });

                  throw playbackError;
                }
              } else {
                let playbackError = true;

                if (typeof resp == "object") {
                  playbackError = resp.data;
                }

                this.hidePlayer();

                eventBus.$emit("showAuthorizeErrorMessage", {
                  state: true,
                  messageData: {
                    title: this.$t("Error"),
                    message: this.$t("Device Limit Reached"),
                  },
                });

                throw playbackError;
              }
            });
          }

          // Else if Concurrency is not enabled
          else {
            if (typeof resp == "object" && !resp.data.errorcode) {
              self.handleFetchContentStreamUrl(resp, episodeList[episodeIndex]);
              loadPlayer = true;
            } else if (typeof resp == "boolean" && resp == true) {
              loadPlayer = true;
            }
            if (loadPlayer == true) {
              self.loadContentMetaData(episodeList[episodeIndex], null);

              let playlist = self.buildPlayerPlaylist(episodeList, false, 0);
              self.playerInstance.loadPlaylist(playlist, totalCount);
              self.playerInstance.loadContent(episodeIndex).then(() => {
                self.playerInstance.play().then(() => {
                  // console.log("Playback successful");
                });
              });
            } else {
              let playbackError = true;

              if (typeof resp == "object") {
                playbackError = resp.data;
              }

              self.hidePlayer();

              throw playbackError;
            }
          }
        })
        .catch((error) => {
          console.log("fetchContentStreamUrlP_catch", error)
          self.hidePlayer();
        });
    },

    async checkPriceModelStatus(contentItem, contentAvailability, packageDetails) {
      if (contentAvailability.pricemodel == 'FREE') {
        return { pricemodel: contentAvailability.pricemodel, availabilityid: contentAvailability.availabilityid, buttonStatus: 'PLAY', isEligible: true }
      } else if ((contentAvailability.pricemodel == "RENTAL" || contentAvailability.pricemodel == "PAID")) {
        this.isARentalContent = true;
        if (this.rentPurchasedActive) {
          return { pricemodel: contentAvailability.pricemodel, availabilityid: contentAvailability.availabilityid, buttonStatus: 'PLAY', isEligible: true }
        } else {
          let res = await this.availabilityDetail(contentAvailability.availabilityid);
          let userCountryCurrency = this.userInfoDetails.subscribercountry == 'IN' ? 'INR' : 'USD';
          let currentPriceItem = {};
          for (let Item of res.data.priceclassdetail) {
            if (Item.currency == userCountryCurrency) {
              currentPriceItem = Item;
            }
          }
          this.rentNowPriceModel = {};
          this.rentNowPriceModel.planname = this.contentDetails.title;
          this.rentNowPriceModel.amount = currentPriceItem.price;
          this.rentNowPriceModel.planid = currentPriceItem.priceclassid;
          this.rentNowPriceModel.priceclassid = currentPriceItem.priceclassid;
          this.rentNowPriceModel.availabilityid = contentAvailability.availabilityid;
          this.rentNowPriceModel.planinterval = res.data.licenseduration;
          this.rentNowPriceModel.currency = currentPriceItem.currency;
          this.rentNowPriceModel.rental = "PURCHASE";
          this.rentNowPriceModel.getPoster = this.getPoster();
          this.rentNowPriceModel.objectid = this.contentDetails.objectid
          let buttonStatus = contentAvailability.pricemodel == "RENTAL" ? "RENT NOW" : "PURCHASE NOW";
          return { pricemodel: contentAvailability.pricemodel, availabilityid: contentAvailability.availabilityid, buttonStatus: buttonStatus, isEligible: false }
        }
      } else if (contentAvailability.pricemodel === "PLAN") {
        if (this.subscriberid && this.subscriptions.length > 0) {
          let isMatched = false;
          this.subscriptions.every((subscription) => {
            // Check if next billing date is greater than today's date.
            if (
              this.getDateForPlayback(subscription.nextbilling) <
              this.getTodaysDate()
            ) {
              isMatched = { buttonStatus: "SUBSCRIBE & WATCH", isEligible: false };
              return false;
            } else {
              let getValue = subscription?.availabilityset?.includes(contentAvailability.availabilityid);
              if (getValue) {
                isMatched = { pricemodel: contentAvailability.pricemodel, availabilityid: contentAvailability.availabilityid, buttonStatus: 'PLAY', isEligible: true }
                return false;
              } else {
                isMatched = { pricemodel: contentAvailability.pricemodel, availabilityid: contentAvailability.availabilityid, buttonStatus: 'SUBSCRIBE & WATCH', isEligible: false }
                return true;
              }
            }
          });
          return isMatched;
        } else if (this.subscriberid && this.subscriptions.length === 0) {
          return { pricemodel: contentAvailability.pricemodel, availabilityid: contentAvailability.availabilityid, buttonStatus: "SUBSCRIBE & WATCH", isEligible: false }
        }
      }
    },
    async getPlayBackAvailabilityStatus(contentItem) {

      let { filteredAvailabilityList, packageDetails } = await this.determineContentPackageAvailabilityDetails(contentItem, this.availabilities);
      if (filteredAvailabilityList.length <= 0) {
        this.buttonStatus = null;
      }
      let contentPriceModeAvailability = [];
      const fetchAvailabilityStatus = async () => {
        const promises = filteredAvailabilityList?.map(async (contentAvailability, i) => {
          let statusContent = await this.checkPriceModelStatus(contentItem, contentAvailability, packageDetails);
          // console.log("statusContent", statusContent);
          return statusContent;
        });

        contentPriceModeAvailability = await Promise.all(promises);
        // console.log(contentPriceModeAvailability, "contentPriceModeAvailability");
        let finalDecisionMade = false;
        contentPriceModeAvailability.forEach((item) => {
          if (!finalDecisionMade && item.isEligible) {
            // If an eligible availability is found, set the button status and stop further checks
            contentItem.playback_details = {
              packageid: packageDetails?.[0]?.packageid,
              availabilityid: item?.availabilityid,
              drmscheme: packageDetails?.[0]?.drmscheme?.[0],
            };
            contentItem.videoType = "Content";
            contentItem.isDrmContent = packageDetails?.[0]?.streamtype === 'NONE' ? true : false;
            this.buttonStatus = item.buttonStatus;
            finalDecisionMade = true;
          } else if (!finalDecisionMade && !item.isEligible) {
            // If no eligible availability is found, but there's an ineligible one, set the button status accordingly
            contentItem.playback_details = {
              packageid: packageDetails.packageid,
              availabilityid: item.availabilityid,
              drmscheme: packageDetails.drmscheme ? packageDetails.drmscheme[0] : null,
            };
            contentItem.videoType = "Content";
            contentItem.isDrmContent = this.isDrmFree ? true : false;
            this.buttonStatus = item.buttonStatus;
          }
        });
      };
      fetchAvailabilityStatus();
      // final disition
    },

    getLiveChanelAvailability(contentItem) {
      let getAvalbilityListByFilter = null;
      let localStoreAvailabilityList = localStorage.getItem("availabilityList");
      localStoreAvailabilityList = JSON.parse(localStoreAvailabilityList);

      if (!this.subscriberid) {
        this.chanelBtnStatus = "LOGIN & WATCH";
      } else if (this.subscriberid && this.subscriptions.length === 0) {

        let contentdetails = contentItem.contentdetails;
        contentdetails.forEach((item) => {
          if (item.mediatype === "LIVE") {
            item.availabilityset.forEach((ContentAvailabilityId) => {
              const foundAvailability = localStoreAvailabilityList.find((availability) => {
                return availability.availabilityid === ContentAvailabilityId;
              });
              if (foundAvailability) {
                getAvalbilityListByFilter = foundAvailability;
                return; // Exit loop early
              }
            });
          }
        });

        if (getAvalbilityListByFilter.pricemodel === "FREE") {
          this.chanelBtnStatus = "WATCH NOW";
        } else {
          this.chanelBtnStatus = "SUBSCRIBE & WATCH";
        }
      } else {
        this.chanelBtnStatus = "WATCH NOW";
      }
    },
    getAvailabilityAndPlanDetails(packageDetails, availabilitiesList) {
      let priceAndAvailabilityData = {};
      // console.log('packageDetails', packageDetails);
      priceAndAvailabilityData.pricemodel = [];
      priceAndAvailabilityData.filteredAvailabilities = [];
      if (!packageDetails) return;
      packageDetails.availabilityset.forEach((availability) => {
        let availabilityIndex = availabilitiesList.findIndex((element) => {
          return element.availabilityid === availability;
        });

        if (availabilityIndex > -1) {
          priceAndAvailabilityData?.pricemodel?.push(
            availabilitiesList[availabilityIndex]?.pricemodel
          );
          priceAndAvailabilityData?.filteredAvailabilities?.push(
            availabilitiesList[availabilityIndex]
          );
        }
      });
      return priceAndAvailabilityData;
    },

    getFilteredPackage(contentItem) {
      let temporaryVideoTag = document.createElement("video");
      let hasNativeHlsSupport = temporaryVideoTag?.canPlayType(
        "application/vnd.apple.mpegurl"
      );

      let isAndroid = navigator?.userAgent?.includes("Android");

      let packageList = [];
      //pick content details if that availability id is there in availability list.
      this.availabilities && this.availabilities?.forEach((availability) => {
        // console.log("availabilities", this.availabilities)
        contentItem && contentItem.contentdetails && contentItem.contentdetails.forEach((element) => {
          if (element.availabilityset && element.availabilityset.includes(availability.availabilityid)) {
            packageList.push(element);
            return
          }
        });
      });
      let packageIndex = packageList.findIndex((contentPackage) => {
        if (!isAndroid && hasNativeHlsSupport != "") {
          return (contentPackage.drmscheme && contentPackage.drmscheme[0] === "FAIRPLAY") || (contentPackage.drmprovider && contentPackage.drmprovider.toLowerCase() === "buydrm");
        } else {
          return (contentPackage.drmscheme && contentPackage.drmscheme[0] === "WIDEVINE") || (contentPackage.drmprovider && contentPackage.drmprovider.toLowerCase() === "buydrm");
        }
      });

      if (packageIndex == -1) {
        return packageList[
          packageList.findIndex((contentPackage) => {
            if (
              contentPackage.streamtype === "HLS" &&
              !isAndroid &&
              hasNativeHlsSupport != ""
            ) {
              this.isDrmFree = false;
              return contentPackage.drmscheme && contentPackage.drmscheme[0] === "NONE";
            } else if (
              contentPackage.streamtype === "DASH" &&
              !(!isAndroid && hasNativeHlsSupport != "")
            ) {
              this.isDrmFree = false;
              return contentPackage.drmscheme && contentPackage.drmscheme[0] === "NONE";
            }
          })
        ];
      } else if (packageIndex > -1) {
        return packageList[packageIndex];
      }
    },

    buildContentStreamRequest(contentItem, isMpegRequired) {
      let payload = {
        contentid: contentItem.objectid,
        params: {
          availabilityid: contentItem.playback_details.availabilityid,
          packageid: contentItem.playback_details.packageid,
        },
      };

      if (isMpegRequired == true) {
        // payload.params.mpegtspackage = "YES";
      }

      return payload;
    },

    fetchContentStreamUrl(contentItem, isMpegRequired) {
      return new Promise((resolve, reject) => {
        if (contentItem.streamUrl != undefined && contentItem.streamUrl != "") {
          resolve(true);
        } else {
          resolve(
            store.dispatch(
              "contentStreamUrl",
              this.buildContentStreamRequest(contentItem, isMpegRequired)
            )
          );
        }
      });
    },

    handleFetchContentStreamUrl(response, contentItem) {
      contentItem.streamUrl = response.data.streamfilename;
      contentItem.scrubbing =
        response.data.packagedfilelist &&
          response.data.packagedfilelist.scrubbing
          ? response.data.packagedfilelist.scrubbing
          : "";
    },

    scrollTop() {
      window.scrollTo(0, 0);
    },

    goBack() {
      this.$router.push({ path: "/" });
    },

    getContentDetail(contentid) {
      if (this.getContentData && this.getContentData.objectid == this.$route?.params?.contentId) {
        this.contentRenderSetup(this.getContentData);
        this.dataFetched = true;
      } else {
        let payload = {
          contentid: contentid,
          params: {
            displaylanguage: this.localDisplayLang,
          },
        };
        store
          .dispatch("contentDetail", payload)
          .then((response) => {
            if (response?.data && !response.data.reason) {
              this.contentRenderSetup(response.data);
            } else {
              eventBus.$emit(`contentDetail-error-response-${payload.contentid}`, response);
              this.dataFetched = true;
              this.showErrorMessage = true;
            }
          })
          .catch((error) => {
            // console.log(error)
            this.showErrorMessage = true;
          });
      }
    },

    getLatestSeason() {
      for (let i = 1; i <= this.seasonCount; i++) {
        this.seasonCountArray.push(i);
      }
      this.seasonNum = this.seasonCountArray.length;
    },

    getPoster() {
      let content = this.contentDetails;
      if (
        content &&
        content.poster &&
        content.poster.length &&
        !content.inwatchlist
      ) {
        let index = content.poster.findIndex((element) => {
          return element.postertype === "LANDSCAPE";
        });

        if (index > -1) {
          if (content?.poster[index].filelist && content?.poster[index].filelist.length > 1) {
            return content?.poster && content?.poster[index]?.filelist[1]?.filename;
          } else {
            return content?.poster && content?.poster[index]?.filelist[0]?.filename;
          }
        }
      } else if (content.inwatchlist) {
        return content.poster && content.poster.landscape;
      } else if (content.thumbnail) {
        return content.thumbnail;
      }
    },

    manageReadMore() {
      this.readMore = true;
    },

    manageReadLess() {
      this.readMore = false;
    },

    fetchTabList() {
      if (this.contentDetails.objecttype == "CHANEL" || this.contentDetails.objecttype == "EPGPROGRAMME") {
        // this.tabs = [{ name: 'Related' }, {name: 'Program Guide'}];
        this.tabs = [{ name: 'Related' }];
      } else if (
        this.contentDetails.objecttype == 'CONTENT' &&
        this.contentDetails.trailer &&
        this.contentDetails.trailer.length > 0
      ) {
        this.tabs = [{ name: 'Related' }, { name: 'Details' }, { name: 'Trailer' }];
      } else if (this.contentDetails.objecttype == 'CONTENT') {
        this.tabs = [{ name: 'Related' }, { name: 'Details' }];
      } else if (
        this.contentDetails.objecttype == 'SERIES' &&
        this.contentDetails.trailer &&
        this.contentDetails.trailer.length > 0
      ) {
        this.tabs = [{ name: 'Episodes' }, { name: "Related" }, { name: 'Details' }, { name: 'Trailer' }];
        if (
          this.contentDetails.tags &&
          this.contentDetails.tags[0].toLowerCase() === "upcoming"
        ) {
          this.tabs.splice(0, 1);
        }
      } else if (
        this.contentDetails.objecttype == 'SERIES' &&
        !this.contentDetails.trailer &&
        this.contentDetails.tags && this.contentDetails.tags.length &&
        this.contentDetails.tags[0].toLowerCase() === "upcoming"
      ) {
        this.tabs = [{ name: 'Related' }, { name: 'Details' }];
      } else if (
        this.contentDetails.objecttype == 'SERIES' &&
        !this.contentDetails.trailer
      ) {
        this.tabs = [{ name: 'Episodes' }, { name: "Related" }, { name: 'Details' }];
      } else if (this.contentDetails.objecttype == 'SERIES') {
        this.tabs = [{ name: 'Episodes' }, { name: "Related" }, { name: 'Details' }, { name: 'Trailer' }];
      } else if (this.contentDetails.objecttype == 'SEASON') {
        this.tabs = [{ name: "Related" }, { name: 'Details' }];
      }
    },

    selectTab(index, item) {
      let currentLanguage = this.getCurrentLanguageUrlBase();

      this.selectTabUI();
      this.selectDefaultTab(index);
      if (item) {
        if (this.contentDetails.objecttype == 'SERIES') {
          if (item == "Episodes" || item == "Tracks") {
            this.selectedSection = index;
            this.$router.push({
              name: "episodes",
              params: { id: this.seasonNum, lang: currentLanguage },
            });
          } else if (item == "Trailer") {
            this.selectedSection = index;
            this.$router.push({
              name: "trailer",
              params: { lang: currentLanguage },
            });
          } else if (item == "Related") {
            this.selectedSection = index;
            this.$router.push({
              name: "related",
              params: { lang: currentLanguage },
            });
          }
        } else {
          if (item == "Trailer") {
            this.selectedSection = index;
            this.$router.replace({
              name: "movieTrailer",
              params: { lang: currentLanguage },
            });
          } else if (item == "Related") {
            this.selectedSection = index;
            this.$router.replace({
              name: "relatedMovie",
              params: { lang: currentLanguage },
            });
          }
        }
      }
    },

    selectTabUI() {
      let fullPath = this.$route.fullPath.split("/");
      let selectedSectionPath = fullPath[fullPath.length - 1];
      var sectionLowercase = this.tabList.map((v) => v.toLowerCase());
      let sectionIndex = sectionLowercase.indexOf(selectedSectionPath);
      if (sectionIndex == -1) {
        sectionIndex = this.selectedSection;
      } else {
        this.selectedSection = sectionIndex;
      }
    },

    selectDefaultTab(index) {
      let currentLanguage = this.getCurrentLanguageUrlBase();

      if (this.contentDetails.objecttype == 'SERIES') {
        if (this.$router.currentRoute.name == "detailPage") {
          this.selectedSection = index;
          this.$router.replace({
            name: "episodes",
            params: { id: this.seasonNum, lang: currentLanguage },
          });
        } else if (
          this.$router.currentRoute.name == "detailPage" &&
          this.contentDetails.tags[0].toLowerCase() === "upcoming" &&
          this.contentDetails.trailer
        ) {
          this.selectedSection = index;
          this.$router.replace({
            name: "trailer",
            params: { lang: currentLanguage },
          });
        } else if (
          this.$router.currentRoute.name == "detailPage" &&
          this.contentDetails.tags[0].toLowerCase() === "upcoming" &&
          !this.contentDetails.trailer
        ) {
          this.selectedSection = index;
          this.$router.replace({
            name: "related",
            params: { lang: currentLanguage },
          });
        }
      } else if (this.contentDetails.objecttype == 'CONTENT' && this.contentDetails.category != 'MOVIE') {
        this.selectedSection = index;
        this.$router.replace({
          name: "related",
          params: { lang: currentLanguage },
        }).then(() => {
          let urlInfo = window.location.pathname;
          urlInfo = urlInfo.substring(0, urlInfo.length - 1);
          window.history.replaceState({}, document.title, urlInfo);
        })

      } else {
        if (
          this.$router.currentRoute.name == "detailPageMovie" &&
          this.contentDetails.objecttype == 'CONTENT' &&
          this.contentDetails.trailer
        ) {



          this.selectedSection = index;
          this.$router.replace({
            name: "movieTrailer",
            params: { lang: currentLanguage },
          }).then(() => {
            let urlInfo = window.location.pathname;
            urlInfo = urlInfo.substring(0, urlInfo.length - 1);
            window.history.replaceState({}, document.title, urlInfo);
          })




        } else if (
          this.$router.currentRoute.name == "detailPageMovie" &&
          this.contentDetails.objecttype == 'CONTENT' &&
          !this.contentDetails.trailer
        ) {


          this.selectedSection = index;
          this.$router.replace({
            name: "relatedMovie",
            params: { lang: currentLanguage },
          }).then(() => {
            let urlInfo = window.location.pathname;
            urlInfo = urlInfo.substring(0, urlInfo.length - 1);
            window.history.replaceState({}, document.title, urlInfo);
          })


        } else if (
          this.$router.currentRoute.name == "movieTrailer" &&
          this.contentDetails.objecttype == 'CONTENT' &&
          this.contentDetails.trailer
        ) {

          this.selectedSection = index;

          this.$router.replace({ name: "movieTrailer", params: { lang: currentLanguage } });

        } else {
          this.selectedSection = index;

          this.$router.replace({ name: "relatedMovie", params: { lang: currentLanguage } }).then(() => {
            let urlInfo = window.location.pathname;
            urlInfo = urlInfo.substring(0, urlInfo.length - 1);
            window.history.replaceState({}, document.title, urlInfo);
          })


        }

      }
    },

    watchTrailer() {
      //To aviod drm check.
      if (
        this.contentDetails.trailer &&
        this.contentDetails.trailer.length > 0
      ) {
        this.showPlayer();
        this.loadContentMetaData(this.contentDetails, null);
        this.contentDetails.isDrmContent = false;
        this.contentDetails.trailerUrl = this.contentDetails?.trailer[0]?.filelist[0]?.filename;

        let locationArr = location?.pathname?.split("/");


        let contentList = [this.contentDetails];
        let playlist = this.buildPlayerPlaylist(contentList, true, 0);
        this.playerInstance.loadPlaylist(playlist, contentList?.length);
        this.playerInstance.loadContent(0).then(() => {
          this.playerInstance.play().then(() => {
            // console.log("Playback successful in detail page");
          });
        });
      } else {
        this.$router.push({ name: "related" });
      }
    },

    showPlayer() {
      if (this.isARentalContent) {
        let expiryPayload = {
          subscriberid: this.subscriberid,
          objectid: this.contentDetails.objectid
        }
        this.subscriberPlaybackExpiry(expiryPayload);
      }
      // console.log("this.playerInstance", this.playerInstance)
      // this.playerInstance.blowUpPlayer();

      this.playerInstance.registerEvent("currentcontentended", this.loadNextContentData);
      this.playerInstance.registerEvent("unloaded", this.videoPlayListPlayerUnloaded);
      window.addEventListener("playerTouchController-event", (status) => {
        let playerTouchControllerHandleStatus = window.playerTouchControllerHandleStatus
      })
      window.addEventListener("EVENTCLOSEPLAYEREVENT", () => {
        // console.log('EVENTCLOSEPLAYEREVENT');
      });
      this.playbackInitiated = true;
      eventBus.$emit("player-visibility", true);
    },

    loadContentMetaData(contentMetaData, seriesMetaData) {
      let metaData = {
        content: contentMetaData,
        seriesContent: seriesMetaData,
      };

      eventBus.$emit("load-player-content", metaData);
    },

    buildPlayerPlaylist(episodeList, isTrailer, startIndex) {
      let playlist = [];
      let contentList = [this.contentDetails];
      for (var aIndex = startIndex; aIndex < episodeList.length; aIndex++) {
        let playlistItem = this.createPlayerPlaylistItem(
          episodeList[aIndex],
          isTrailer,
          contentList[0].watchedTime ? contentList[0].watchedTime : 0
        );
        playlist.push(playlistItem);
      }
      // console.log('playlist', playlist)
      return playlist;
    },

    loadNextContentData(endedContentIndex) {
      let episodeIndex = endedContentIndex + 1;
      let nextepisodeid = this.episodesList[endedContentIndex + 1] ? this.episodesList[endedContentIndex + 1].objectid : "";
      localStorage.setItem("nextepisodeid", nextepisodeid);
      if (episodeIndex < this.episodesList.length) {
        if (Array.isArray(this.episodesList) && this.episodesList[episodeIndex].playbacktype == "EXTERNALURLBASEDPLAYBACK") {
          this.actBuyDRMToPlayer(episodeIndex, this.episodesList, this.episodesList.length, true);
        } else {
          this.loadChosenIndexContent(episodeIndex, this.episodesList, this.episodesList.length, false);
        }
      } else {
        this.hidePlayer();
      }
    },

    hidePlayer() {
      this.playerInstance.shrinkPlayer();

      eventBus.$emit("player-visibility", false);

      let payload = { content: null, seriesContent: null };
      eventBus.$emit("load-player-content", payload);

      this.playerInstance.unloadPlayer(true);
    },

    createPlayerPlaylistItem(content, isTrailer, contentHead) {
      let payload = {
        contentId:
          content.objectid != undefined && content.objectid != ""
            ? content.objectid
            : "",
        contenttype: content.category,
        title: content.title,
        description: content.shortdescription,
        thumbnail: this.extractThumbnailPoster(content),
        source: isTrailer
          ? [content.trailerUrl]
          : content.streamUrl != undefined && content.streamUrl != ""
            ? [content.streamUrl]
            : [],
        licenseServer: this.appConfig.providerDetails.drmLicenseUrl,
        packageid: content.playback_details
          ? content.playback_details.packageid
          : "",
        providerid: this.appConfig.providerDetails.providerId,
        drmscheme: content.playback_details
          ? content.playback_details.drmscheme
          : "",
        availabilityid: content.playback_details
          ? content.playback_details.availabilityid
          : "",
        providerSession: localStorage.getItem("sessionToken"),
        skip:
          !isTrailer && content.skip && Array.isArray(content.skip) && content.skip.length > 0
            ? content.skip
            : "",
        position: contentHead,
        scrubbing: content.scrubbing ? content.scrubbing[0] : "",
        adversity: {
          advisory: content.advisory,
          pgrating: content.pgrating,
          defaultgenre: content.defaultgenre || content.genre,
        },
        playbacktype: content.playbacktype || "",
        contentprovider: content.contentprovider || ""
      };

      payload.isDrmContent = this.isDrmFree ? true : false;

      let playlistItem = new vLivePlaylistItem(payload);

      return playlistItem;
    },

    extractThumbnailPoster(contentItem) {
      let posterKey = contentItem.poster;
      let thumbNailUrl = null;

      if (
        posterKey &&
        Array.isArray(posterKey) == true &&
        posterKey.length > 0
      ) {
        // let posterFileList = posterKey[0].filelist;

        let posterFileList = posterKey?.find(
          (posterKeyNode) => posterKeyNode.postertype == "LANDSCAPE"
        )?.filelist;

        if (
          posterFileList &&
          Array.isArray(posterFileList) == true &&
          posterFileList.length > 0
        ) {
          let thumbNailNode = posterFileList.find(
            (posterNode) => posterNode.quality == "THUMBNAIL" || posterNode.quality == "SD" || posterNode.quality == "HD"
          );

          if (thumbNailNode) {
            thumbNailUrl = thumbNailNode?.filename;
          }
        }
      }

      return thumbNailUrl;
    },

    createLink(medium) {
      this.shareLink = window.location.href;
    },

    copyLink() {
      navigator.clipboard.writeText(this.shareLink);
      showSnackBar("Link copied to clipboard");
    },

    tvChanelHandle(chanelBtnStatus) {
      if (
        !this.subscriberid &&
        (chanelBtnStatus === "PLAY" || chanelBtnStatus === "LOGIN & WATCH" || chanelBtnStatus === "SUBSCRIBE & WATCH")
      ) {
        //open lookup popup.
        let payload = {
          state: true,
          formType: "lookup",
        };
        eventBus.$emit("authPopup", payload);
      } else if (
        this.subscriberid &&
        chanelBtnStatus === "SUBSCRIBE & WATCH" &&
        this.subscriberid !== this.profileid
      ) {
        eventBus.$emit("showAuthorizeErrorMessage", {
          state: true,
          messageData: {
            title: "Error",
            message: this.$t(
              "Sorry! Admin only allowed to the payment. Please contact your Admin"
            ),
          },
        });
      }
      // For master Profile
      else if (this.subscriberid && chanelBtnStatus === "SUBSCRIBE & WATCH") {

        this.setRegActiveDeeplink(true);
        //show subscribe popup.
        // let payload = { listType: "onlySubscribe" };
        // eventBus.$emit("subscribePopup", payload);
        localStorage.setItem("paymentGetwaySaveUrl", location.href)

        let currentLanguage = this.getCurrentLanguageUrlBase();
        this.$router.push({ name: 'seeAllPlans', params: { lang: currentLanguage } });
      } else {
        if (chanelBtnStatus === "WATCH NOW") {
          eventBus.$emit("live-player-active", this.contentDetails);
        }
      }
    },

    handleSeries(isDeeplink = false, status = false) {
      if (!this.subscriberid && (this.seriesBtnStatus === "WATCH NOW" || this.seriesBtnStatus === "LOGIN & WATCH" || this.seriesBtnStatus === "SUBSCRIBE & WATCH")) {
        //open lookup popup.
        let payload = {
          state: true,
          formType: "lookup",
        };
        eventBus.$emit("authPopup", payload);
      } else if (this.subscriberid && this.seriesBtnStatus === "SUBSCRIBE & WATCH" && this.subscriberid !== this.profileid) {
        eventBus.$emit("showAuthorizeErrorMessage", {
          state: true,
          messageData: {
            title: "Error",
            message: this.$t(
              "Sorry! Admin only allowed to the payment. Please contact your Admin"
            ),
          },
        });
      }
      // For master Profile
      else if (this.subscriberid && this.seriesBtnStatus === "SUBSCRIBE & WATCH") {

        this.setRegActiveDeeplink(true);
        //show subscribe popup.
        // let payload = { listType: "onlySubscribe" };
        // eventBus.$emit("subscribePopup", payload);
        localStorage.setItem("paymentGetwaySaveUrl", location.href)
        let currentLanguage = this.getCurrentLanguageUrlBase();
        this.$router.push({ name: 'seeAllPlans', params: { lang: currentLanguage } });

      } else if (this.subscriberid && (this.seriesBtnStatus === "PLAY" || this.seriesBtnStatus === "WATCH NOW")) {
        if (
          !this.isContentPlayableParentalLock(
            this.getCurrentProfile(),
            this.contentDetails.pgrating
          ) &&
          !status
        ) {
          if (this.parentalControlsEnabled) {
            this.callbackParentalLockFlag = "MOVIE-PLAY";
            this.showPinModal = true;
            return;
          }
        }
        this.showPlayer();
        let contentList = [...this.episodesList];


        if (Array.isArray(contentList) && contentList[0].playbacktype == "EXTERNALURLBASEDPLAYBACK") {
          this.actBuyDRMToPlayer(0, contentList, contentList.length, true);
        } else {
          this.loadChosenIndexContent(0, contentList, contentList.length, true);
        }
        // this.closePopup();
      }
      else if (this.subscriberid && (this.seriesBtnStatus == "RENT NOW" || this.seriesBtnStatus == "PURCHASE NOW")) {
        if (this.subscriberid !== this.profileid) {
          eventBus.$emit("showAuthorizeErrorMessage", {
            state: true,
            messageData: {
              title: this.$t("Error"),
              message: this.$t("Sorry! Admin only allowed to the payment. Please contact your Admin")
            },
          });
          return;
        } else {
          eventBus.$emit("open-home-rzp-modal", this.rentNowPriceModel);
        }
      } else if ((this.seriesBtnStatus === "SUBSCRIBE OR PURCHASE" || this.seriesBtnStatus === "SUBSCRIBE OR RENT")) {
        if (this.subscriberid !== this.profileid) {
          eventBus.$emit("showAuthorizeErrorMessage", {
            state: true,
            messageData: {
              title: this.$t("Error"),
              message: this.$t("Sorry! Admin only allowed to the payment. Please contact your Admin")
            },
          });
          return;
        }
        this.isPopupClose = true;
      }
    },

    closePopup() {
      this.isLiveContentAccessError = false;
    }
  },
  beforeDestroy() {
    eventBus.$off(`contentDetail-response-${this.contentId}`);
    eventBus.$off("changeLanguageView");
  },
  mixins: [Utility, Redirection],
};
</script>


<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-extra-large: 1200px;

.no-found {
  width: inherit;
  text-align: center;
  margin: 2rem 0 2rem 0;
  color: var(--clr-close-light-dark);
}

.mute {
  align-self: end;
  margin-right: 2rem;
  position: absolute;
  right: 3%;
  bottom: 22%;
  z-index: 99;
}

.button-primary {
  width: 100%;
  padding: 0.7rem;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 0.03rem;
  line-height: 0.7rem;
  text-align: center;
  border-radius: 0.3rem;
  color: #000;
  background: $primary-btn-color;
  ;
  border-color: transparent !important;
  transition: 0.3s linear all;
  border: 2px solid #ffffff;
  cursor: pointer;
}

.login-watch {
  background: $primary-btn-color;
  color: #FFFFFF;
  padding: 0.8rem;
  border-radius: 0.3rem;
  font-size: 0.8rem;
  font-weight: bold;
  text-align: center;
  border-color: transparent !important;
  cursor: pointer;
}

.container {
  // font-size: 16px;
  width: 100%;

  .section-1 {
    position: relative;

    .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: fill;
    }

    .carousel-poster-img {
      min-width: 100%;
      min-height: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .image-wrapper {
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8) 10%, rgba(0, 0, 0, 0.7) 23%, transparent 50%), linear-gradient(to top, black 8%, rgba(0, 0, 0, 1) 25%, rgba(0, 0, 0, 0.3) 34%);

      .info {
        display: flex;
        flex-direction: column;
        width: clamp(2rem, 100%, 95%);
        row-gap: 0.5rem;
        margin: 0 auto;

        .title {
          font-size: 2rem;
          color: #fff;
          text-transform: uppercase;
          width: 45%;
        }

        .mob-abjust {
          display: flex;
          flex-direction: column;
          row-gap: 1rem;
          width: 45%;

          .mob-adjust-2 {
            display: flex;
            flex-direction: column;
            row-gap: 0.7rem;

            .description {
              font-size: 1rem;
              font-weight: 300;
            }

            .general-info {
              display: flex;
              gap: 0.75rem;
              .channel-no {
                border: 0.1rem solid gray;
                border-radius: 0.2rem;
                padding: 0.2rem 0.6rem;
              }
              .catchup-period {
                background: red;
                border-radius: 0.2rem;
                padding: 0.2rem 0.4rem;
                align-content: end;

              }
              .pg-rating {
                background-color: gray;
                border-radius: 0.2rem;
                padding: 0.2rem 0.6rem;
              }
            }

            .tags {
              display: flex;
              gap: 0.5rem;
            }

            // .tags div:not(:first-child)::before {
            //   content: '\2022';
            //   /* Unicode for bullet point */
            //   margin-right: 0.6rem;
            //   /* Adjust as needed for spacing */
            // }
          }

          .buttons {
            display: flex;
            // flex-direction: column;
            column-gap: 1rem;
            align-items: center;
            margin-bottom: 1.5rem;

            .main-btn {
              background-color: #ffffff;
              padding: 0.6rem 2.1rem;
              color: black;
              border-radius: 0.6rem;
              height: fit-content;
              text-align: center;
            }

            .secondary-btns {
              display: flex;
              /* justify-content: flex-end; */
              column-gap: 1rem;
              align-items: center;
            }
          }
        }
      }

    }
  }

  .section-2 {
    position: relative;
    z-index: 1;
    min-height: 10rem;
    width: clamp(2rem, 100%, 95%);

    .cast-crew {
      display: grid;

      .cast {
        width: 100%;

      }

      .crew {
        margin-top: 2%;
        width: 100%;

      }

      // display: grid;
    }
  }
}
@media only screen and (max-width: 767px) {
  .container {
    font-size: 12px;

    .section-1 {
      margin-top: 4rem;
      display: flex;
      flex-direction: column;
      .pointer {
        cursor: pointer;
      }

      .mute {
        display: none
      }

      .image-wrapper {
        width: 100%;
        padding: 0;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8) 10%, rgba(0, 0, 0, 0.7) 23%, transparent 50%), linear-gradient(to top, black 0%, black 2.5%, rgba(0, 0, 0, 0.3) 34%);
        .info {
          width: 100%;
          padding: 0.5rem;
          gap: 0.5rem;
          .title {
            font-size: 1rem;
            text-transform: uppercase;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            text-wrap: nowrap;
          }

          .mob-abjust {
            display: flex;
            row-gap: 0.5rem !important;
            justify-content: space-around;
            width: 99%;

            .mob-adjust-2 {
              display: flex;
              row-gap: 0.5rem !important;

              .general-info {
                .pg-rating {
                  background: none;
                  border: 0.1rem solid gray;
                  border-radius: 0.2rem;
                  padding: 0.2rem 0.4rem 0.2rem 0.4rem;
                }
              }
              .tags{
                div {
                  padding: 0.2rem;
                  background: gray;
                  border-radius: 0.1rem;
                  font-size: clamp(0.5rem, 1.5vh, 1rem);
                }
              }
              .description {
                color: #777;
                font-size: clamp(0.5rem, 1.5vh, 1rem);
              }
            }

            .buttons {
              align-items: center;
              row-gap: 0.5rem;
              margin-bottom: 0 !important;

              .secondary-btns {
                justify-content: space-evenly;
              }
            }
          }
        }
      }
    }
    .section-2 {
      width: clamp(2rem, 100%, 100%) !important;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .container {
    font-size: 12px;

    .section-1 {
      position: relative;
      // margin-top: 4rem;

      .pointer {
        cursor: pointer;
      }

      .mute {
        display: none
      }

      .image-wrapper {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding-left: 5rem;
        top: 0%;
        left: 0%;
        height: 100%;
        width: 100%;
        padding: 0;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8) 10%, rgba(0, 0, 0, 0.7) 23%, transparent 50%), linear-gradient(to top, black 0%, black 2.5%, rgba(0, 0, 0, 0.3) 34%);

        .description {
          display: block;
          font-size: 0.8rem !important;
          width: 75%;
        }

        .info {
          width: 100%;
          // transform: translate(0px, 400px);
          padding: 0px 1.5rem;

          .title {
            font-size: 1rem;
            text-transform: uppercase;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            text-wrap: nowrap;
          }

          .mob-abjust {
            display: flex;
            row-gap: 0.3rem !important;
            justify-content: space-around;
            width: 99%;

            .mob-adjust-2 {
              display: flex;
              row-gap: 0.3rem !important;

              .general-info {}

              .tags div:not(:first-child)::before {
                content: '\2022';
                /* Unicode for bullet point */
                margin-right: 0.6rem;
                /* Adjust as needed for spacing */
              }
            }

            .buttons {
              align-items: center;
              row-gap: 0.5rem;
              margin-bottom: 0 !important;

              .secondary-btns {
                justify-content: space-evenly;
              }
            }
          }
        }
      }
    }

    .section-2 {
      // background: rgba(0, 0, 0, 0.9);
      margin: -6rem auto 0;
      .cast-crew {
        .cast {
          width: 100%;
        }

        .crew {
          width: 100%;
        }
      }
      .tags div:not(:first-child)::before {
        content: '\2022';
        /* Unicode for bullet point */
        margin-right: 0.6rem;
        /* Adjust as needed for spacing */
      }
    }
  }
}

.image-detail-ratio {
  aspect-ratio: 2 / 1;
}

@media only screen and (min-width: 1024px)and (max-width: 1441px) {
  .image-detail-ratio {
    aspect-ratio: 16 / 9 !important;
  }

  .image-wrapper {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // padding-left: 5rem;
      top: 0%;
      left: 0%;
      height: 100%;
      width: 100%;
      .info {
        .mob-abjust {
          .mob-adjust-2 {

            .tags div:not(:first-child)::before {
              content: '\2022';
              /* Unicode for bullet point */
              margin-right: 0.6rem;
              /* Adjust as needed for spacing */
            }
          }
        }
      }
  }
  .section-2 {
    margin: -11rem auto 0;
  }
}
@media only screen and (min-width: 1441px) {
  .image-wrapper {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // padding-left: 5rem;
      top: 0%;
      left: 0%;
      height: 100%;
      width: 100%;
      .info {
        .mob-abjust {
          .mob-adjust-2 {

            .tags div:not(:first-child)::before {
              content: '\2022';
              /* Unicode for bullet point */
              margin-right: 0.6rem;
              /* Adjust as needed for spacing */
            }
          }
        }
      }
  }
  .section-2 {
    margin: auto;
    margin-top: -17rem;
  }
}
.confirmation-box {
  .confirmation-body {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    text-align: center;
    .login-button {
      width: 50%;
    }
  }
}
.program-guide {
  padding: 1rem;
}
</style>

